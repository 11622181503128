import { Layout, SEO, Title } from "../components"
import { graphql, useStaticQuery } from "gatsby"

import AboutsComponent from "../components/Abouts"
import React from "react"
import styled from "styled-components"

const BiographyPage = () => {
  const data = useStaticQuery(query)

  return (
    <Layout>
      <Wrapper>
        <SEO title="Biographie" />
        <Title title="Biographie" />
        <section className="biography-page">
          <AboutsComponent nodes={data.allStrapiBiography.nodes} />
        </section>
      </Wrapper>
    </Layout>
  )
}

const query = graphql`
  query IndexQuery {
    allStrapiBiography {
      nodes {
        info {
          paragraph
          id
          image {
            childImageSharp {
              fluid {
                ...GatsbyImageSharpFluid
              }
            }
          }
        }
      }
    }
  }
`

const Wrapper = styled.main`
  background: var(--clr-grey-9);
  min-height: calc(100vh - 4rem);

  h2 {
    font-size: 2rem;
    color: var(--clr-grey-1);
    margin: 2rem 0;
  }
  @media (min-width: 800px) {
    h2 {
      font-size: 3rem;
    }
  }
`

export default BiographyPage
